::-webkit-scrollbar{
    width: 8px;
    background:#0a183b0e;
}
::-webkit-scrollbar-track{
    background: transparent;
}
::-webkit-scrollbar-thumb{
    background: var(--color-gray);
    border-radius: 2px;
    overflow: hidden;
}
::-webkit-scrollbar-thumb:hover{
    background: var(--color-primary-dark);
}

input, textarea, select {
    touch-action: manipulation !important;
}
