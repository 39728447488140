@media only screen and (max-width: 1025px) and (orientation: portrait){
    /* -- wrapper style -- */
    /* .wrapper{
        overflow: hidden;
    } */
    .main{
        flex-direction: column;
        padding-left: 0;
    }
    .main-scroll{
        margin-top: var(--top-navbar-height);
    }

    .section-head .section-head-img-container img{
        width: 100%;
    }
    .navbar .menu-switch-btn {
        display: none;
    }


    /*  --- navbar style --- */
    .main .navbar{
        height: var(--top-navbar-height);
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        margin-right: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        border-radius: unset;
        box-shadow: var(--box-shadow-navbar-bottom);
    }
    .top-header{
        border: none;
        box-shadow: none;
    }
    .web-logo img{
        width: 3.7rem;
    }
    .main .navbar .nav-title{
        flex: 1;
        align-items: flex-start;
        justify-content: flex-start;
        margin-left: 0rem;
    }
    .nav-profile .web-logo img{
        width: 60px;
        height: 60px;
    }
    .nav-profile h2{
        font-size: 1rem;
    }
    .nav-profile .view-profile-btn p,
    .nav-profile .view-profile-btn i,
    .nav-profile .view-profile-btn span{
        font-size: 0.825rem;
    }
    .main .navbar .web-name{
       font-size: 1.3rem;
       margin-top: -4px;
    }
    .navbar .nav-title p{
        font-size: 0.625rem;
    }
    .main .navbar .web-developer{
        font-size: 1.1rem;
        color: #dedefc;
    }
    .main .navbar .nav-menu{
        justify-content: start;
        align-items: center;
        width: 280px;
        position: absolute;
        left: 0;
        top: 0;
        height: 100dvh;
        z-index: -2;
        padding-bottom: 2rem;
        background-color: var(--color-bg-navbar);
        margin-top: var(--top-navbar-height);
        transform: translateX(-100%);
        transition: transform 0.3s ease; /* Smooth transition */
        box-shadow: 4px 0 4px -2px rgba(0, 0, 0, 0.05); 
        border-right: 0.5px solid var(--color-bg-border);
        z-index: 998;
    }
    .main .navbar .nav-menu .nav-div{
        position: relative;

    }
    .main .navbar .point-div{
       width: 20%;
    }

    .main .navbar .nav-menu .nav-active{
       border-radius: unset;
    }
    .navbar .nav-menu .nav-div .nav-div-item a{
        padding: 0.8rem 1rem;
        margin: 0.6rem 1.5rem;
    }
    .main .navbar .nav-menu .nav-div i{
        font-size: 1.45rem;
        margin-right: 20px;
    }

    /* navbar icon style */
    .navbar .menu-btn{
        display: none;
    }
    .web-logo{
        margin-top: 0;
    }

    .navbar .menu-ic,
    .navbar .share-ic{
        font-size: 2.7rem;
        color: var(--color-white);
        display: block;
    }
    .navbar .share-ic{
        font-size: 2.5rem;
    }
    .main .navbar .nav-menu .close-ic{
        color: var(--color-white);
        position: absolute;
        top: 40px;
        right: 0;
        display: block;
        font-size: 2rem;
    }
     /* navbar menu icon style */
    #menuBtn {
        background-color: transparent;
        border: none;
        cursor: pointer;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        
    }
    .bar {
        width: 28px;
        height: 4px;
        background-color: white;
        margin: 3px 0;
        transition: transform 0.3s ease, opacity 0.3s ease;
    }

    #menuBtn.close .bar:nth-child(1) {
        transform: translateY(9px) rotate(45deg);
    }

    #menuBtn.close .bar:nth-child(2) {
        opacity: 0;
    }

    #menuBtn.close .bar:nth-child(3) {
        transform: translateY(-9px) rotate(-45deg);
    }

    /* navbar bottom design */
    #play-store-btn, .app-store-btn{
        width: 175px;
        height: 54px;
        padding: 0.8rem 0.5rem;
    }
    .app-store-btn{
        margin-bottom: 3.5rem;
    }
    #play-store-btn .play-store-txt p, .app-store-btn .play-store-txt p{
        font-size: 0.725rem;
    }
    #play-store-btn .play-store-txt h3, .app-store-btn .play-store-txt h3{
        font-size: 0.825rem;
    }
    #play-store-btn .bxl-play-store, .app-store-btn .bxl-apple{
        font-size: 1.825rem;
        margin-right: 0.25rem;
    }
    .main .navbar .profile{
        padding: 2rem 0;
        font-size: 1.6rem;
    }

    .chat-page-content,
    .bible-content{
        margin-top: var(--top-navbar-height);
        height: calc(100dvh - var(--top-navbar-height)) !important;
    }
    .footer .left-con .logo-div{
        max-width: 400px;
    }
}

@media only screen and (max-width: 480px){
    .navbar .nav-menu .nav-div .nav-div-item a{
        padding: 0.625rem 0.8rem;
        margin: 0.6rem 1.5rem;
    }
    .nav-profile .web-logo img{
        width: 40px;
        height: 40px;
    }
    .navbar .nav-menu .nav-div .nav-div-item a span{
        font-size: 0.9rem;
    }
    .footer{
        padding: 2.5rem var(--spacing-sec-left-right);
        flex-direction: column-reverse;
    }
    .footer .left-con, 
    .footer .right-con{
        width: 100%;
    }
    .footer .right-con{
        margin-bottom: 4rem;
    }
    .footer .left-con .logo-div{
        max-width: 100%;
    }
    .main .navbar .nav-menu .nav-div i{
        font-size: 1.15rem;
    }
}